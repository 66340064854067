<template>
  <page-title-component title="Create Campaign"></page-title-component>

  <div class="row mb-3">
    <div class="col-12">
      <div  class="card">
        <div class="card-header bg-info">
          Default Values
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
                <div class="form-group">
                  <label>ASIN</label>
                  <input class="form-control"
                         :disabled="loading"
                         v-model="defaultValues.asin"
                         type="text"
                         placeholder="ASIN">
                </div>

              <div class="form-group">
                <label>Campaign Name</label>
                <input class="form-control"
                       :disabled="loading"
                       v-model="defaultValues.name"
                       type="text"
                       placeholder="Campaign Name">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="budget">Spend:</label>
                <div class="row">
                  <div class="col-md-6">
                    <div class="custom-control custom-checkbox checkbox-primary pl-0">
                      <input type="checkbox"
                             class="custom-control-input"
                             :id="'fixed_amount'"
                             v-model="defaultValues.isFixed"
                             value="fixed">
                      <label class="custom-control-label"
                             :for="'fixed_amount'">Fixed Amount</label>
                    </div>
                    <div class="pl-1">
                      <div class="input-group">
                        <input type="number"
                               :disabled="!defaultValues.isFixed" v-model="defaultValues.fixedAmount"
                               class="form-control" placeholder="Amount" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="custom-control custom-checkbox checkbox-primary pl-0">
                      <input type="checkbox"
                             class="custom-control-input"
                             :id="'percentage'"
                             v-model="defaultValues.isPercentage"
                             value="percentage">
                      <label class="custom-control-label"
                             :for="'percentage'">Percentage of Sales</label>
                    </div>
                    <div class="pl-1">
                      <input type="number"
                             v-model="defaultValues.percentageAmount"
                             :disabled="!defaultValues.isPercentage"
                             class="form-control"
                             placeholder="Percentage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6" v-for="user in users" :key="'user_'+user.id">
      <div  class="card">
        <div class="card-header">
          {{ user.name }} ({{ user.email }})
        </div>
        <div class="card-body">
          <select class="form-control"
                  @change="getBrandsForCampaign(user)"
                  v-model="user.form.campaign_id">
            <option value="">Select Campaign</option>
            <option :value="cam.id"
                    v-for="cam in user.campaigns"
                    :key="'cam_'+cam.id">{{ cam.influencer_name }}</option>
            <option value="new">Create New</option>
          </select>

          <div v-if="user.form.campaign_id === 'new'">
            <hr>
            <div class="row">
              <div class="col-md-12 m-auto">
                <div class="form-group">
                  <label>Amazon Ad Account</label>
                  <select class="form-control"
                          @change="amazonAccountChangeEvent(user)"
                          v-model="user.form.amazon_profile"
                          :disabled="loading">
                    <option value="">Select Amazon Profile</option>
                    <option :value="account.profileId"
                            v-for="account in user.amazonAccounts"
                            :key="'amazon_account_'+account.profileId">{{ account.accountInfo.name }} ({{ account.currencyCode }})</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 m-auto">
                <div class="form-group">
                  <label>ASIN</label>
                  <input class="form-control"
                         :disabled="loading"
                         v-model="user.form.asin"
                         type="text"
                         placeholder="ASIN">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 m-auto">
                <div class="form-group">
                  <label>Campaign Name</label>
                  <input class="form-control"
                         :disabled="loading"
                         v-model="user.form.name"
                         type="text"
                         placeholder="Campaign Name">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 m-auto">
                <div class="form-group">
                  <label for="budget">Spend:</label>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="custom-control custom-checkbox checkbox-primary pl-0">
                        <input type="checkbox"
                               class="custom-control-input"
                               :id="'fixed_amount_'+user.id"
                               value="fixed"
                               v-model="user.form.isFixed">
                        <label class="custom-control-label"
                               :for="'fixed_amount_'+user.id">Fixed Amount</label>
                      </div>
                      <div class="pl-1">
                        <div class="input-group">
                          <input type="number" :disabled="!user.form.isFixed" v-model="user.form.fixedAmount" class="form-control" placeholder="Amount" />
                          <div class="input-group-append">
                            <button class="btn btn-dark dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">{{ user.form.currency }} </button>
                            <div class="dropdown-menu currency-dropdown-menu">
                              <a class="dropdown-item dropdown-item-sm"
                                 v-for="currency in currencies"
                                 :key="'currency_'+currency.id"
                                 @click="user.form.currency = currency.code">{{ currency.code }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="custom-control custom-checkbox checkbox-primary pl-0">
                        <input type="checkbox"
                               class="custom-control-input"
                               :id="'percentage_'+user.id"
                               value="percentage"
                               v-model="user.form.isPercentage">
                        <label class="custom-control-label"
                               :for="'percentage_'+user.id">Percentage of Sales</label>
                      </div>
                      <div class="pl-1">
                        <input type="number"
                               v-model="user.form.percentageAmount"
                               :disabled="!user.form.isPercentage" class="form-control" placeholder="Percentage" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-bordered table-sm">
                  <thead>
                  <tr>
                    <th>Influencer Email(s)</th>
                    <th class="text-right">
                      <a class="text-success"
                         href="#" @click.prevent="user.form.emails.push('')">+ Add New</a>
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-for="(email, i) in user.form.emails" :key="'email_'+i">
                    <td>
                      <input type="text"
                             v-model="user.form.emails[i]"
                             class="form-control form-control-sm">
                    </td>
                    <td class="text-right">
                      <a class="text-danger" @click.prevent="user.form.emails.splice(i, 1)">
                        <i class="fas fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="custom-control custom-checkbox checkbox-primary pl-0">
                  <input type="checkbox"
                         class="custom-control-input"
                         :id="'short_url_'+user.id"
                         v-model="user.form.create_short_url">
                  <label class="custom-control-label"
                         :for="'short_url_'+user.id">Create Short URL</label>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary"
              :disabled="v$.users.$invalid || loading"
              @click="createCampaign">Create</button>
    </div>
  </div>

  <select-brand-modal ref="selectBrandModal" />
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import SelectBrandModal from "@/views/campaign/SelectBrandModal.vue";
import useVuelidate from '@vuelidate/core'
import {minLength, required} from '@vuelidate/validators'

const checkCampaign = () => {
  let result = true;

  /*users.forEach(user => {
    if (user.form.amazon_profile.length === 0 ||
        user.form.name.length === 0 ||
        user.form.asin.length === 0) {
      result = false;
    }
  })*/

  return result;
}
export default {
  components: {PageTitleComponent, SelectBrandModal},
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      loading: false,
      users: {
        required,
        minLength: minLength(1),
        checkCampaign
      },
    }
  },
  data() {
    return {
      defaultValues: {
        name: '',
        asin: '',
        fixedAmount: '',
        isFixed: true,
        isPercentage: false,
        percentageAmount: '',
      },
      users: [],
      form: [],
      currencies: []
    }
  },
  watch: {
    defaultValues: {
      handler(newValue) {
        this.users.forEach(user => {
          user.form.name = newValue.name;
          user.form.asin = newValue.asin;
          user.form.isFixed = newValue.isFixed;
          user.form.fixedAmount = newValue.fixedAmount;
          user.form.isPercentage = newValue.isPercentage;
          user.form.percentageAmount = newValue.percentageAmount;
        })
      },
      deep: true
    }
  },
  created() {
    this.loadUsers();
    this.loadCurrencies()
  },
  methods: {
    getBrandsForCampaign(user) {
      if (user.form.campaign_id !== '' && user.form.campaign_id !== 'new') {
        this.axios.get('/campaign/brands', {
          params: {
            campaign_id: user.form.campaign_id,
            user_id: user.id
          }
        }).then((res) => {
          let brands = res.data.data.brands;
          let found = res.data.data.found;

          if (brands.length === 1 && found) {
            user.form.brand_id = brands[0].id;
          } else if (brands.length > 1) {
            this.$refs.selectBrandModal.show(brands, user.form, found);
          }
        })
      }
    },
    createCampaign() {
      /*this.$store.commit('createCampaign/setUsers', this.users);
      this.$router.push({name: 'send_email'})*/

      this.loading = true;

      this.axios.post('/campaign/create', this.users)
        .then(() => {
          // this.showSuccessMsg('Created Successfully!');
          this.$store.commit('createCampaign/setUsers', this.users);
          this.$router.push({name: 'send_email'})
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadUsers() {
      this.axios.get('/get-users', {
        params: {
          user_ids: this.$store.state.createCampaign.selectedUserIds
        }
      }).then(res => {
        let users = res.data.data;

        users.forEach(user => {
          user.amazonAccounts = [];
          user.campaigns = [];
          user.form = {
            existingCampaign: '0',
            campaign_id: '',
            currency: 'USD',
            emails: [''],
            amazon_profile: '',
            create_short_url: true,
            asin: '',
            name: '',
            isFixed: true,
            fixedAmount: '',
            isPercentage: false,
            percentageAmount: '',
          }
        })

        this.users = users;

        this.users.forEach(user => {
          this.loadAmazonAccounts(user)
          this.loadCampaigns(user)
        })
      })
    },
    amazonAccountChangeEvent(user) {
      let account = user.amazonAccounts.find(a => a.profileId === user.form.amazon_profile);

      if (account) {
        user.form.currency = account.currencyCode;
      }
    },
    loadCurrencies() {
      this.axios.get('/currencies')
        .then(res => {
          this.currencies = res.data.data;
        })
    },
    loadCampaigns(user) {
      this.axios.get('/business-settings/campaigns', {
        params: {
          userId: user.id
        }
      }).then(res => {
        user.campaigns = res.data.data
      });
    },
    loadAmazonAccounts(user) {
      this.axios.get('/amazon-accounts', {
        params: {
          id: user.id
        }
      }).then(res => {
        user.amazonAccounts = res.data
      });
    }
  }
}
</script>