<template>
  <page-title-component title="Send Email" />

  <div class="row mb-3">
    <div class="col-12">
      <div class="card">
        <div class="card-header bg-info">
          Default
        </div>
        <div class="card-body">
          <select class="form-control"
                  @change="changePrompt(null)"
                  :disabled="fetchingEmail"
                  v-model="selectedPrompt">
            <option value="">Select Prompt</option>
            <option :value="prompt.prompt"
                    v-for="prompt in prompts"
                    :key="'prompt_'+prompt.id">{{ prompt.prompt }}</option>
            <option value="custom">Custom</option>
          </select>

          <div class="row mt-2" v-if="selectedPrompt === 'custom'">
            <div class="col-md-10">
              <input class="form-control"
                     :disabled="fetchingEmail"
                     v-model="customPrompt">
            </div>
            <div class="col-md-2">
              <button class="btn btn-block btn-primary"
                      :disabled="fetchingEmail"
                      @click.prevent="fetchCustom(null)">Fetch</button>
            </div>
          </div>

          <div class="row mt-2" v-if="selectedPrompt === 'custom'">
            <div class="col-md-12">
              <span role="button"
                    @click.prevent="customPrompt += '$BrandName'"
                    class="badge badge-primary mr-2 mt-2">$BrandName</span>
              <span role="button"
                    @click.prevent="customPrompt += '$ProductName'"
                    class="badge badge-primary mr-2 mt-2">$ProductName</span>
              <span role="button"
                    @click.prevent="customPrompt += '$Logo'"
                    class="badge badge-primary mr-2 mt-2">$Logo</span>
              <span role="button"
                    @click.prevent="customPrompt += '$BrandPhoto'"
                    class="badge badge-primary mr-2 mt-2">$BrandPhoto</span>
              <span role="button"
                    @click.prevent="customPrompt += '$BrandColor'"
                    class="badge badge-primary mr-2 mt-2">$BrandColor</span>
              <span role="button"
                    @click.prevent="customPrompt += '$PromoCode'"
                    class="badge badge-primary mr-2 mt-2">$PromoCode</span>
              <span role="button"
                    @click.prevent="customPrompt += '$PercentageOff'"
                    class="badge badge-primary mr-2 mt-2">$PercentageOff</span>
              <span role="button"
                    @click.prevent="customPrompt += '$SignOff'"
                    class="badge badge-primary mr-2 mt-2">$SignOff</span>
              <span role="button"
                    @click.prevent="customPrompt += '$BrandCategory'"
                    class="badge badge-primary mr-2 mt-2">$BrandCategory</span>
              <span role="button"
                    @click.prevent="customPrompt += '$StoreLink'"
                    class="badge badge-primary mr-2 mt-2">$StoreLink</span>
              <span role="button"
                    @click.prevent="customPrompt += '$BrandProductType'"
                    class="badge badge-primary mr-2 mt-2">$BrandProductType</span>
              <span role="button"
                    @click.prevent="customPrompt += '$AttributionLink'"
                    class="badge badge-primary mr-2 mt-2">$AttributionLink</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6" v-for="user in users" :key="'user_'+user.id">
      <div  class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-6">
              {{ user.user.name }} ({{ user.user.email }})
            </div>
            <div class="col-6">
              <select class="form-control form-control-sm"
                      @change="changePrompt(user)"
                      :disabled="fetchingEmail"
                      v-model="user.selectedPrompt">
                <option value="">Select Prompt</option>
                <option :value="prompt.prompt"
                        v-for="prompt in prompts"
                        :key="'prompt_'+prompt.id">{{ prompt.prompt }}</option>
                <option value="custom">Custom</option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="user.selectedPrompt === 'custom'">
            <div class="row">
              <div class="col-md-10">
                <textarea class="form-control"
                          rows="4"
                          :disabled="fetchingEmail"
                          v-model="user.customPrompt"></textarea>
              </div>
              <div class="col-md-2">
                <button class="btn btn-block btn-primary btn-block h-100"
                        :disabled="fetchingEmail"
                        @click.prevent="fetchCustom(user)"><i class="fas fa-redo"></i></button>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <div class="alert alert-secondary">
                  {{ customPromptWithReplaceValues(user.customPrompt, user.brand_values) }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-10">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <span role="button"
                          v-for="(v, i) in Object.keys(user.brand_values)"
                          :key="'bv_'+i"
                          :title="user.brand_values[v]"
                          @click.prevent="user.customPrompt += v"
                          class="badge badge-primary mb-1 mr-1">{{ v }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>

          <div class="row mt-2 mb-2" v-if="user.brand">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  {{ user.brand.BrandName }}
                </div>
                <div class="card-body p-1">
                  <div class="row">
                    <div class="col-6">
                      <table class="table table-striped table-borderless brand-table"
                             style="width: calc(100% + 25px); table-layout: fixed">
                        <tbody>
                        <tr class="table-dark">
                          <th class="text-center" colspan="2">Brand Information</th>
                        </tr>
                        <tr>
                          <th>Logo(s)</th>
                          <td>{{ user.brand.Logo }}</td>
                        </tr>
                        <tr>
                          <th>Promo code</th>
                          <td>{{ user.brand.PromoCode }}</td>
                        </tr>
                        <tr>
                          <th>Brand Colors</th>
                          <td>{{ user.brand.BrandColor }}</td>
                        </tr>
                        <tr>
                          <th>Percentage Off in discount</th>
                          <td>{{ user.brand.PercentageOff }}</td>
                        </tr>
                        <tr>
                          <th>Brand Sign-Off</th>
                          <td>{{ user.brand.SignOff }}</td>
                        </tr>
                        <tr>
                          <th>Brand Category</th>
                          <td>{{ user.brand.BrandCategory }}</td>
                        </tr>
                        <tr>
                          <th>Store Attribution Link</th>
                          <td>{{ user.brand.AttributionLink }}</td>
                        </tr>
                        <tr>
                          <th>Store Product Type</th>
                          <td>{{ user.brand.BrandProductType }}</td>
                        </tr>
                        </tbody>

                      </table>
                    </div>
                    <div class="col-6">
                      <table class="table table-bordered brand-table table-striped"
                             style="width: 100%; table-layout: fixed">
                        <tbody>
                        <tr class="table-dark">
                          <th class="text-center" colspan="2">Attribution Link</th>
                        </tr>
                        <tr class="table-active">
                          <th>Campaign</th>
                          <th>Product Name</th>
                        </tr>

                        <tr v-for="(product, i) in user.brand.products"
                            :key="'user_product_'+user.id+'_'+i">
                          <td>{{ product.campaign_name }}</td>
                          <td>{{ product.product_name }}</td>
                        </tr>
                        </tbody>
                      </table>

                      <table class="table table-striped table-bordered brand-table"
                             style="width: 100%; table-layout: fixed">
                        <tbody>
                        <tr class="table-dark">
                          <th class="text-center" colspan="2">Brand Photo(s)</th>
                        </tr>
                        <tr class="table-active">
                          <th>Link</th>
                          <th>Preview</th>
                        </tr>

                        <tr v-for="(brandPhoto, i) in user.brand.BrandPhotos"
                            :key="'user_brand_photo_'+user.id+'_'+i">
                          <td>{{ brandPhoto }}</td>
                          <td>
                            <img :src="brandPhoto"
                                 @error="imageLoadOnError"
                                 height="40" />
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <editor :api-key="tinyMCEApiKey"
                      v-model="user.body_html"
                      output-format="html"/>
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div class="col-12">
              <label>Aweber List</label>
              <select class="select2"
                      v-show="user.showLists"
                      :id="'select-lists-'+user.user.id"
                      style="width: 100%"
                      multiple="multiple"
                      v-model="user.list_ids"
                      data-placeholder="Select List">
                <option :value="list.id"
                        v-for="list in user.lists"
                        :key="'list_'+list.id">{{ list.name }}</option>
              </select>

              <label>Email Subject Line</label>
              <input type="text" v-model="user.aweber_name" class="form-control">
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <input type="radio"
                     value="now"
                     :disabled="user.user.business_draft_only_mode"
                     v-model="user.delivery"> Now
              <input type="radio" value="draft" v-model="user.delivery"> Draft
              <input type="radio"
                     value="schedule"
                     :disabled="user.user.business_draft_only_mode"
                     v-model="user.delivery"> Schedule
            </div>
            <div class="col-6">
              <input class="form-control"
                     v-if="user.delivery === 'schedule'"
                     v-model="user.delivery_time"
                     type="datetime-local">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary"
              :disabled="loading"
              @click="sendEmail">Send</button>
    </div>
  </div>
  <app-loading ref="appLoading" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import Editor from '@tinymce/tinymce-vue'
import AppLoading from "@/components/AppLoading.vue";

export default {
  components: {PageTitleComponent, Editor, AppLoading},
  data() {
    return {
      fetchingEmail: false,
      selectedPrompt: '',
      customPrompt: '',
      users: [],
      prompts: [],
    }
  },
  created() {
    this.loadPrompts();
    this.users = this.$store.state.createCampaign.users.map(user => {
      return {
        body_html: '',
        brand_values: {},
        brand_id: user.form.brand_id || null,
        campaign_id: user.form.campaign_id,
        selectedPrompt: '',
        customPrompt: '',
        lists: [],
        list_ids: [],
        showLists: false,
        delivery: 'now',
        delivery_time: null,
        aweber_name: '',
        user: {
          id: user.id,
          name: user.name,
          email: user.email,
          business_draft_only_mode: user.business_draft_only_mode,
        },
        asin: user.form.asin
      }
    });

    this.users.forEach(user => {
      this.loadLists(user);

      if (user.user.business_draft_only_mode) {
        user.delivery = 'draft';
      }

      if (user.brand_id) {
        this.loadBrand(user);
      }
    })
  },
  methods: {
    customPromptWithReplaceValues(prompt, brandValues) {
      let newPrompt = prompt;

      Object.keys(brandValues).forEach(bv => {
        newPrompt = newPrompt.replaceAll(bv, brandValues[bv]);
      })

      return newPrompt;
    },
    fetchCustom(user = null) {
      this.fetchingEmail = true;
      this.$refs.appLoading.show();

      if (user) {
        this.fetchCustomEmail(user, user.customPrompt);
      } else {
        this.users.forEach(user => {
          this.fetchCustomEmail(user, this.customPrompt);
        })
      }
    },
    fetchCustomEmail(user, prompt) {
      this.axios.post('/fetch-custom-email', {
        userId: user.id,
        brandId: user.brand_id,
        campaignId: user.campaign_id,
        prompt: prompt
      }).then(res => {
        user.body_html = res.data.data.replaceAll('\n', '<br>');
      }).finally(() => {
        this.fetchingEmail = false;
        this.$refs.appLoading.hide();
      })
    },
    changePrompt(user) {
      let prompt = null;

      if (user) {
        prompt = user.selectedPrompt;
      } else {
        prompt = this.selectedPrompt
      }

      if (prompt && prompt !== 'custom') {
        this.fetchingEmail = true;
        this.$refs.appLoading.show();

        this.axios.post('/fetch-email', {prompt: prompt})
            .then(res => {
              if (user) {
                user.body_html = res.data.data.replaceAll('\n', '<br>');
              } else {
                this.users.forEach(user => {
                  user.body_html = res.data.data.replaceAll('\n', '<br>');
                  user.selectedPrompt = this.selectedPrompt;
                })
              }
            })
            .finally(() => {
              this.fetchingEmail = false;
              this.$refs.appLoading.hide();
            })
      }
    },
    loadPrompts() {
      this.prompts = [];

      this.axios.get('/prompts')
          .then(res => {
            this.prompts = res.data.data
          })
    },
    sendEmail() {
      this.loading = true;

      this.axios.post('/email/send', {users: this.users})
          .then(() => {
            this.$router.push({ name: 'licenses' })
          })
          .finally(() => {
            this.loading = false;
          });
    },
    loadBrand(user) {
      this.axios.get('/business-settings/brands/' + user.brand_id, {
        params: {
          campaignId: user.campaign_id
        }
      }).then(res => {
        user.brand = res.data.data.brand;
        user.brand_values = res.data.data.values;
      });
    },
    loadLists(user) {
      user.lists = [];

      this.axios.get('/email/lists?userId='+user.user.id)
          .then(res => {
            user.lists = res.data.data;
            this.initListsSelect2(user)
          })
    },
    initListsSelect2(user) {
      // let self = this;

      window.$('#select-lists-'+user.user.id).select2();

      window.$('#select-lists-'+user.user.id).on('change', function () {
        user.list_ids = window.$('#select-lists-'+user.user.id).select2("val");
      });

      user.showLists = true;
    },
  }
}
</script>

<style scoped>
.brand-table {
  font-size: 0.7rem;
}

.brand-table td, .brand-table th {
  padding: .25rem !important;
}

.brand-table tr {
  border-bottom: 1px solid #c0c0c0;
}

.brand-table tr:last-child {
  border-bottom: none;
}
</style>