<template>
  <div class="modal fade"
       id="modal-select-brand-modal"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Choose Brand</h5>
<!--          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>-->
        </div><!--end modal-header-->
        <div class="modal-body">
          <ul class="list-group">
            <li class="list-group-item"
                style="cursor: pointer"
                @click="selectBrand(brand)"
                v-for="brand in brands"
                :key="'brand_'+brand.id">
              {{ brand.BrandName }}
            </li>
            <li class="list-group-item"
                style="cursor: pointer"
                v-if="!found"
                @click="selectBrand(null)">
              None
            </li>
          </ul>
        </div><!--end modal-body-->
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      brands: [],
      userForm: null,
      found: false,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-select-brand-modal'), {
      backdrop: 'static'
    });
  },
  methods: {
    show(brands, userForm, found) {
      this.brands = brands;
      this.userForm = userForm;
      this.found = found;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    selectBrand(brand) {
      if (brand) {
        this.userForm.brand_id = brand.id;
      } else {
        this.userForm.brand_id = null;
      }

      this.hide();
    }
  }
}
</script>